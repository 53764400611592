@use "../../styles/colors";

.item {
  position: relative;

  .message {
    position: fixed;
    bottom: 80px;
    left: 10px;
    right: 10px;
    text-align: center;
    padding: 10px 15px;
    border-radius: 5px;
    background-color: colors.$black;
    color: colors.$white;
    font-size: 14px;
    font-weight: 700;
    box-shadow: 0 0 5px colors.transparent(0, 0.25);
    z-index: 100;
  }

  .media {
    display: flex;
    overflow-x: scroll;
    gap: 2px;
    -ms-overflow-style: none;
    scrollbar-width: none;
    scroll-snap-type: x mandatory;

    &::-webkit-scrollbar {
      display: none;
    }

    .mediaItem {
      width: 95%;
      flex-grow: 0;
      flex-shrink: 0;
      scroll-snap-align: start;
    }
  }

  .actions {
    display: flex;
    width: 100%;
    padding: 10px 5px;
    align-items: center;
    justify-content: flex-end;
    border-bottom: 1px solid colors.$grey;

    p {
      font-size: 14px;
    }

    button {
      height: 50px;
      border-radius: 40px;
      font-size: 16px;
      padding: 0 30px;
      margin: 0 5px;
      font-weight: 700;
      background-color: colors.$lightGrey;
      flex: 1;

      &.black {
        color: colors.$white;
        background-color: colors.$black;
      }

      &.secondary {
        background-color: transparent;
        text-decoration: underline;
        padding: 0 20px;
      }
    }
  }

  .details {
    &.main {
      padding: 20px;
      border-bottom: 1px solid colors.$grey;
    }

    .sku {
      font-size: 12px;
      color: colors.$darkGrey;
      margin-bottom: 5px;
    }

    .name {
      margin-bottom: 10px;
      font-size: 24px;

      .variant {
        font-weight: 400;
      }
    }

    .price {
      .currency {
        font-size: 14px;
      }
    }

    .accordion {
      display: flex;
      flex-direction: column;
      padding: 20px;

      &:not(:last-child) {
        border-bottom: 1px solid colors.$grey;
      }

      .label {
        font-weight: 700;
        margin-bottom: 5px;
      }

      .value {
        position: relative;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;

        .overlay {
          opacity: 1;
          height: 100%;
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          background: linear-gradient(
            180deg,
            colors.transparent(255, 0.5) 25px,
            colors.$white 100%
          );
        }

        &.open {
          display: block;
          .overlay {
            opacity: 0;
          }
        }
      }
    }

    .addToCart {
      width: 100%;
      height: 80px;
      font-size: 20px;
      font-weight: 700;
      background-color: colors.$black;
      color: colors.$white;
    }
  }

  .siblings {
    display: flex;
    border-bottom: 1px solid colors.$grey;
    align-items: center;

    .variant,
    .size {
      flex: 1;
      text-align: center;
      padding: 20px 0;
      font-weight: 700;
      font-size: 14px;
      cursor: pointer;
    }

    .variant {
      border-right: 1px solid colors.$grey;

      span {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        display: inline-block;
        margin-right: 5px;
        border: 1px solid colors.$grey;
      }
    }
  }

  .availability {
    padding: 15px 0;
    font-size: 14px;
    border-bottom: 1px solid colors.$grey;

    .location {
      display: flex;
      align-items: center;
      justify-content: center;

      &.webshop {
        margin-top: 5px;
      }

      .status {
        width: 6px;
        height: 6px;
        border-radius: 50%;
        display: inline-block;
        margin-right: 5px;
      }

      &.inStock {
        .status {
          background-color: colors.$green;
        }
      }

      &.fewLeft {
        .status {
          background-color: colors.$orange;
        }
      }

      &.outOfStock {
        .status {
          background-color: colors.$red;
        }
      }
    }
  }
}

@use "../../styles/colors";

.cartItem {
  display: flex;
  width: 100%;
  margin-bottom: 10px;
  align-items: center;

  img {
    width: 80px;
    border-radius: 5px;
  }

  .details {
    flex: 1;
    padding: 10px 0 10px 10px;
    text-align: left;

    .name {
      width: 100%;
      display: flex;
      font-size: 14px;
      margin-bottom: 5px;
      justify-content: space-between;
      align-items: center;

      .store {
        padding: 5px 10px;
        display: inline-block;
        border-radius: 40px;
        border: 1px solid colors.$grey;
        line-height: 1;
        font-size: 12px;
        font-weight: 400;
      }
    }

    p {
      font-size: 12px;
    }

    button {
      text-decoration: underline;
      margin-top: 5px;
      cursor: pointer;
      color: colors.$red;
      font-size: 12px;
      background-color: transparent;
    }
  }
}

@use "../../styles/colors";

.slideIn {
  transform: translateY(100vh);
  background-color: transparent;
}

.slideInActive {
  transform: translateY(0);
  transition: all 200ms ease;
}

.slideInFinished {
  background-color: colors.transparent(0, 0.25);
  transition: all 200ms ease;
}

.slideOutActive {
  transform: translateY(100vh);
  background-color: transparent;
  transition: all 400ms ease;
}

@use "../../styles/colors";

$verticalOffset: calc(calc(100vw - 80vw) / 2); //75vw = 4:3ar
$horizontalOffset: calc(calc(100vw - 80vw) / 2);

.scanner {
  display: flex;
  align-items: flex-end;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 990;

  .content {
    width: 100%;
    display: flex;
    flex-direction: column;
    background: colors.$white;
    border-radius: 20px 20px 0 0;
    box-shadow: 0 0 5px colors.transparent(0, 0.25);
    position: relative;
    text-align: left;

    .title {
      font-size: 16px;
      line-height: 24px;
      padding: 20px;

      .close {
        float: right;
        text-decoration: underline;
        font-size: 12px;
        font-weight: 400;
        cursor: pointer;
      }
    }

    .container {
      position: relative;
      overflow: hidden;
      min-height: 100vw;
      background-color: colors.$black;

      .reader {
        video {
          vertical-align: middle;
        }
      }

      .overlay {
        position: absolute;
        border-width: $verticalOffset $horizontalOffset;
        border-style: solid;
        border-color: colors.transparent(0, 0.5);
        inset: 0;
        z-index: 100;
      }

      /*.frame {
        position: absolute;
        top: $verticalOffset;
        left: $horizontalOffset;
        right: $horizontalOffset;
        bottom: $verticalOffset;
        border: 2px solid colors.$white;
        box-shadow: 0 0 50px 0 colors.transparent(0, 0.25);
        z-index: 200;
      }*/

      .frame {
        position: absolute;
        width: 40px;
        height: 40px;
        z-index: 150;

        &.topLeft {
          top: calc($verticalOffset - 2px);
          left: calc($horizontalOffset - 2px);
          border-top: 4px solid colors.$white;
          border-left: 4px solid colors.$white;
        }

        &.topRight {
          top: calc($verticalOffset - 2px);
          right: calc($horizontalOffset - 2px);
          border-top: 4px solid colors.$white;
          border-right: 4px solid colors.$white;
        }

        &.bottomLeft {
          bottom: calc($verticalOffset - 2px);
          left: calc($horizontalOffset - 2px);
          border-bottom: 4px solid colors.$white;
          border-left: 4px solid colors.$white;
        }

        &.bottomRight {
          bottom: calc($verticalOffset - 2px);
          right: calc($horizontalOffset - 2px);
          border-bottom: 4px solid colors.$white;
          border-right: 4px solid colors.$white;
        }
      }

      .blur {
        position: absolute;
        background-color: colors.transparent(0, 0.1);
        backdrop-filter: blur(3px);
        overflow: hidden;
        z-index: 100;

        &.top {
          top: 0;
          left: 0;
          right: 0;
          height: $verticalOffset;
        }

        &.left {
          left: 0;
          top: $verticalOffset;
          bottom: $verticalOffset;
          width: $horizontalOffset;
        }

        &.bottom {
          bottom: 0;
          left: 0;
          right: 0;
          height: $verticalOffset;
        }

        &.right {
          right: 0;
          top: $verticalOffset;
          bottom: $verticalOffset;
          width: $horizontalOffset;
        }
      }
    }
  }
}

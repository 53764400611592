@use "../../styles/colors";

.cart {
  display: flex;
  align-items: flex-end;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 990;

  .content {
    width: 100%;
    min-height: 50vh;
    max-height: 90vh;
    display: flex;
    flex-direction: column;
    background: colors.$white;
    padding: 20px;
    border-radius: 20px 20px 0 0;
    box-shadow: 0 0 5px colors.transparent(0, 0.25);
    position: relative;
    text-align: left;

    .title {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 20px;

      .close {
        float: right;
        text-decoration: underline;
        font-size: 12px;
        font-weight: 400;
        cursor: pointer;
      }
    }

    .items {
      overflow-y: auto;
      -ms-overflow-style: none;
      scrollbar-width: none;
      scroll-snap-type: x mandatory;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    a {
      display: contents;
    }

    .goToCheckout {
      height: 50px;
      width: 100%;
      border-radius: 40px;
      font-size: 16px;
      padding: 0 30px;
      color: colors.$white;
      background-color: colors.$black;
      font-weight: 700;
      margin-top: auto;
    }
  }
}

@use "../../styles/colors";

.store {
  .content {
    text-align: center;
    padding: 40px 20px;

    .label {
      font-size: 12px;
      text-transform: uppercase;
      margin-bottom: 5px;
    }

    .store {
    }

    .branch {
    }

    .scanButton {
      height: 50px;
      width: 100%;
      border-radius: 40px;
      font-size: 16px;
      padding: 0 30px;
      background-color: colors.$black;
      color: colors.$white;
      font-weight: 700;
      margin-top: 40px;
    }
  }
}

@use "../../styles/colors";

.checkout {
  height: 100%;
  height: calc(100% - 54px);
  display: flex;
  flex-direction: column;
  position: relative;

  .title {
    font-size: 16px;
    line-height: 24px;
  }

  .subtitle {
    font-size: 14px;
    margin-bottom: 10px;
  }

  .content {
    margin: 10px 10px 0;
    border-radius: 10px;
    background-color: colors.$lightGrey;
    padding: 20px;

    &.highlighted {
      background-color: colors.$black;
      color: colors.$white;
    }

    .items {
      &:not(:last-child) {
        padding-bottom: 20px;
      }
    }
  }

  .paymentMethods {
    padding: 20px;
    margin-top: 10px;
    border-bottom: 1px solid colors.$grey;
    border-top: 1px solid colors.$grey;

    .method {
      display: flex;
      margin-top: 10px;
      align-items: center;
      font-size: 14px;

      .radio {
        width: 16px;
        height: 16px;
        border-radius: 50%;
        border: 1px solid colors.$black;
        margin-right: 5px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      &.selected {
        .radio {
          &::after {
            content: "";
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: colors.$black;
          }
        }
      }
    }
  }

  .summary {
    //margin-top: auto;
    padding: 20px;

    a {
      display: contents;
    }

    .continue {
      height: 60px;
      width: 100%;
      border-radius: 40px;
      font-size: 16px;
      padding: 0 30px;
      background-color: colors.$black;
      color: colors.$white;
      font-weight: 700;

      span {
        text-transform: capitalize;
      }

      &.vipps {
        background-color: colors.$vipps;
      }
    }
  }
}

@use "_colors";

//@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;600;800&display=swap');

@font-face {
  font-family: "Graphik Web";
  src: url("../../public/font/Graphik-Regular-Web.woff");
  src: url("../../public/font/Graphik-Regular-Web.woff2") format("woff2"),
    url("../../public/font/Graphik-Regular-Web.woff") format("woff"),
    url("../../public/font/Graphik-Regular.otf") format("opentype");
  font-weight: 400;
}

@font-face {
  font-family: "Graphik Web";
  src: url("../../public/font/Graphik-Semibold.otf") format("opentype");
  font-weight: 700;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

img {
  max-width: 100%;
  max-height: 100%;
  border: none;
  vertical-align: middle;
}

a {
  color: inherit;
  text-decoration: none;
}

body {
  font-family: "Graphik Web", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  background-color: colors.$black;
}

h1,
h2,
h3,
h4,
h4,
h5 {
  font-weight: 700;
}

button {
  outline: none;
  border: none;
  appearance: none;
  cursor: pointer;
  color: inherit;
}

.App {
  width: 100%;
  max-width: 540px;
  aspect-ratio: 9/19.5;
  margin: auto;
  background-color: colors.$white;
}

$white: #fff;
$grey: #ddd;
$darkGrey: #999;
$lightGrey: #eee;
$black: #111;
$green: #41ac47;
$orange: #fac88f;
$vipps: #ff5b24;
$red: #f42f2f;

@function transparent($color, $alpha) {
  $rgba: rgb($color, $color, $color, $alpha);

  @return $rgba;
}

@use "../../styles/colors";

$bleed: 25px;
$borderWidth: 4px;

.scannerButton {
  position: fixed;
  bottom: 10px;
  right: 10px;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: colors.$black;
  box-shadow: 0 0 10px colors.transparent(0, 0.25);
  z-index: 100;

  .frame {
    position: absolute;
    width: 10px;
    height: 10px;
    z-index: 150;

    &.topLeft {
      top: $bleed;
      left: $bleed;
      border-top: $borderWidth solid colors.$white;
      border-left: $borderWidth solid colors.$white;
    }

    &.topRight {
      top: $bleed;
      right: $bleed;
      border-top: $borderWidth solid colors.$white;
      border-right: $borderWidth solid colors.$white;
    }

    &.bottomLeft {
      bottom: $bleed;
      left: $bleed;
      border-bottom: $borderWidth solid colors.$white;
      border-left: $borderWidth solid colors.$white;
    }

    &.bottomRight {
      bottom: $bleed;
      right: $bleed;
      border-bottom: $borderWidth solid colors.$white;
      border-right: $borderWidth solid colors.$white;
    }
  }
}

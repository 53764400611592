@use "../../styles/colors";

$bleed: 10px;
$borderWidth: 2px;

.header {
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
  padding: 10px 15px;
  text-align: center;
  transition: 200ms ease;
  border-bottom: 1px solid colors.$grey;
  background-color: colors.$white;
  position: relative;
  z-index: 950;

  &.sticky {
    position: sticky;
    top: 0;
    padding: 5px 15px;
    border-color: transparent;
    box-shadow: 0 0 5px colors.transparent(0, 0.25);
  }

  .button {
    font-size: 14px;
    border-radius: 20px;
    padding: 10px 15px;
    font-weight: 700;
    background-color: colors.$lightGrey;
    min-width: 36px;
    position: relative;
    cursor: pointer;

    &.black {
      background-color: colors.$black;
      text-decoration: none;
      color: colors.$white;
    }

    .frame {
      position: absolute;
      width: 5px;
      height: 5px;
      z-index: 150;

      &.topLeft {
        top: $bleed;
        left: $bleed;
        border-top: $borderWidth solid colors.$white;
        border-left: $borderWidth solid colors.$white;
      }

      &.topRight {
        top: $bleed;
        right: $bleed;
        border-top: $borderWidth solid colors.$white;
        border-right: $borderWidth solid colors.$white;
      }

      &.bottomLeft {
        bottom: $bleed;
        left: $bleed;
        border-bottom: $borderWidth solid colors.$white;
        border-left: $borderWidth solid colors.$white;
      }

      &.bottomRight {
        bottom: $bleed;
        right: $bleed;
        border-bottom: $borderWidth solid colors.$white;
        border-right: $borderWidth solid colors.$white;
      }
    }
  }

  .store {
    font-size: 14px;
    font-weight: 700;
    margin: auto;

    .branch {
      font-weight: 400;
    }
  }
}

@use "../../styles/colors";

.checkoutItem {
  display: flex;
  width: 100%;
  align-items: center;

  .details {
    flex: 1;
    text-align: left;

    .name {
      width: 100%;
      display: flex;
      font-size: 14px;
      align-items: center;

      .meta {
        font-weight: 400;
        font-size: 12px;
        margin-left: 20px;
      }
    }

    button {
      text-decoration: underline;
      cursor: pointer;
      color: colors.$red;
      font-size: 12px;
      background-color: transparent;
    }
  }
}
